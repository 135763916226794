import styled from '@emotion/styled';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slider as PureSlider,
  Slide,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from 'react';
import {
  EMOTION_BREAKPOINTS,
  isSmallScreen,
} from '../../../constants/breakpoints';
import theme from '../../../theme';
import './styles.css';

const imageStyles = {
  cursor: 'pointer',
};
const selectedStyles = {
  ...imageStyles,
  border: '2px solid ' + theme.colors.blue,
};

const Container = styled.div`
  position: relative;
  max-width: calc(100% - 8px);

  ${EMOTION_BREAKPOINTS.sm} {
    max-width: 610px;
    margin: auto;
  }

  .buttonBack {
    position: absolute;
    left: -24px;
    top: 50%;
    transform: translateY(-50%);

    background-color: transparent;
    border: none;
    ${EMOTION_BREAKPOINTS.sm} {
      left: -34px;
    }
  }

  .buttonNext {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);

    background-color: transparent;
    border: none;
    ${EMOTION_BREAKPOINTS.sm} {
      right: -26px;
    }
  }
`;

export const Carousel = ({ images, onSelectImage, selectedImage }) => {
  return (
    <CarouselProvider
      visibleSlides={isSmallScreen() ? 1 : 3}
      totalSlides={images.length}
      step={1}
      naturalSlideWidth={400}
      naturalSlideHeight={300}
    >
      <Container className="container">
        <PureSlider className="slider">
          {images.map((image, index) => (
            <Slide
              key={index}
              index={index}
              onClick={() => onSelectImage(index)}
            >
              <GatsbyImage
                image={image}
                alt="Package Image"
                placeholder="blurred"
                width={200}
                quality={100}
                style={selectedImage === index ? selectedStyles : imageStyles}
                className="carousel__image"
              />
            </Slide>
          ))}
        </PureSlider>
        <ButtonBack className="buttonBack">
          <StaticImage
            src="../../../images/arrow_back_ios-24px.svg"
            alt="left arrow"
            placeholder="blurred"
            width={30}
            quality={100}
          />
        </ButtonBack>
        <ButtonNext className="buttonNext">
          <StaticImage
            src="../../../images/arrow_forward_ios-24px.svg"
            alt="right arrow"
            placeholder="blurred"
            width={30}
            quality={100}
          />
        </ButtonNext>
      </Container>
    </CarouselProvider>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSelectImage: PropTypes.func.isRequired,
};
