import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';

const Container = styled.div`
  // hide on mobile
  display: none;
  ${EMOTION_BREAKPOINTS.md} {
    display: block;
    width: 610px;
    height: fit-content;
    position: relative;
  }
`;

const InnerContainer = styled.div`
  ${EMOTION_BREAKPOINTS.md} {
    position: absolute;
    top: 34px;
    left: 82px;
    width: 492px;
  }
`;

const Placeholder = styled.div`
  display: none;
  ${EMOTION_BREAKPOINTS.md} {
    display: block;
  }
`;

export const Hero = ({ image }) => {
  return (
    <Container>
      <Placeholder>
        <StaticImage
          src="../../../images/ipad-placeholder.png"
          alt="iPad Placeholder"
          placeholder="blurred"
          width={610}
          quality={100}
        />
      </Placeholder>
      <InnerContainer>
        <GatsbyImage
          image={image}
          alt="Package Image"
          placeholder="blurred"
          width={610}
          quality={100}
        />
      </InnerContainer>
    </Container>
  );
};

Hero.propTypes = {
  image: PropTypes.object.isRequired,
};
