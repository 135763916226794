import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';
import { Carousel } from '../../UI/Carousel';
import { Hero } from './Hero';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const CarouselContainer = styled.div`
  padding: 40px 0 0 20px;
  ${EMOTION_BREAKPOINTS.sm} {
    width: 100%;
  }
`;

export const PackageShowcase = ({ images }) => {
  const [selectedImage, setSelectedImage] = React.useState(0);

  return (
    <Container>
      {/* hidden on small devices */}
      <Hero image={images[selectedImage]} />
      <CarouselContainer>
        {/* enlarged on small devices */}
        <Carousel
          images={images}
          selectedImage={selectedImage}
          onSelectImage={setSelectedImage}
        />
      </CarouselContainer>
    </Container>
  );
};

PackageShowcase.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
