import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import theme from '../../theme';
import { DownloadAppButton } from '../UI/DownloadAppButton';
import { PackageShowcase } from './PackageShowcase';
import { Link } from '../UI/StandardLink';
import { EMOTION_BREAKPOINTS } from '../../constants/breakpoints';

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;

  ${EMOTION_BREAKPOINTS.md} {
    flex-direction: row;
  }
`;

const ImageSection = styled.div`
  width: 100%;
  height: 100%;
`;

const DetailsSection = styled.div`
  height: 100%;
  padding: 0 20px;

  ${EMOTION_BREAKPOINTS.sm} {
    padding: 0 60px;
  }
`;

const Subject = styled.h2`
  color: ${theme.colors.blue};
  font-size: 21px;
  font-weight: 400;
`;

const Title = styled.h1`
  color: ${theme.colors.text};
  font-size: 30px;
  font-weight: 400;
`;

const Description = styled.p`
  color: #818181;
  font-family: Roboto;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const CTASection = styled.div`
  display: none;

  ${EMOTION_BREAKPOINTS.md} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 180px;
    > * {
      margin-top: 10px;
    }
  }
`;

const MobileCTASection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  padding: 0 20px;
  > * {
    margin-top: 10px;
  }
  ${EMOTION_BREAKPOINTS.md} {
    display: none;
  }
`;

export const PackageDetails = ({ title, subject, description, images }) => {
  return (
    <Container>
      <MobileCTASection>
        <DownloadAppButton />
        <Link to="/">Back to Home</Link>
      </MobileCTASection>
      <ImageSection>
        <PackageShowcase images={images} />
      </ImageSection>
      <DetailsSection>
        <Subject>{subject}</Subject>
        <Title>{title}</Title>
        <Description>
          {typeof description === 'string'
            ? description
            : renderRichText(description)}
        </Description>
        <CTASection>
          <DownloadAppButton />
          <Link to="/">Back to Home</Link>
        </CTASection>
      </DetailsSection>
    </Container>
  );
};

PackageDetails.propTypes = {
  title: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }).isRequired,
  ]).isRequired,
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};
